<template>
  <OProfileCard class="c-profile-suggestion" :data-id="id" v-bind="cardProps">
    <BRow v-if="!submitted">
      <BCol>
        <BFormCheckbox v-model="allowed">{{ checkboxLabel }}</BFormCheckbox>
      </BCol>
      <BCol class="text-right">
        <BButton :variant="suggestVariant" :disabled="isSuggestDisabled" @click="onClickSuggest">
          {{ suggestLabel }}
          <BSpinner v-if="isLoading" small />
        </BButton>
      </BCol>
    </BRow>
    <div v-else>
      {{ $t(`${prefix}.submitted-label`) }}
    </div>
  </OProfileCard>
</template>

<script>
import { resolveMessage } from 'library/src/utilities/resolve-message';
import { BCol, BRow, BButton, BFormCheckbox, BSpinner } from 'bootstrap-vue';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
import { fullname as fullnameFilter } from 'library/components/src/tools/filters/fullname';
import OProfileCard from '../../../../../components/o/profile/card';

const prefix = 'vacancy.o.profile-suggestion';

export default {
  name: 'o-vacancy-profile-suggestion',
  mixins: [loadingMixin],
  components: {
    OProfileCard,
    BFormCheckbox,
    BButton,
    BSpinner,
    BCol,
    BRow,
  },
  data() {
    return {
      allowed: false,
      submitted: false,
    };
  },
  props: {
    vacancyId: String,
    profile: Object,
  },
  computed: {
    prefix: () => prefix,
    id() {
      return this.profile._id;
    },
    suggestLabel() {
      if (this.loadingError) {
        const errorMessage = resolveMessage(this.loadingError);
        return this.$t(`${prefix}.errors.${errorMessage.message}`);
      }
      return this.$t(`${prefix}.suggest-label`);
    },
    suggestVariant() {
      if (this.loadingError) {
        return 'danger';
      }
      return 'primary';
    },
    cardProps() {
      const { internalId, person } = this.profile;
      return { person, internalId };
    },
    person() {
      return this.profile.person;
    },
    checkboxLabel() {
      const name = fullnameFilter(this.person);
      return this.$t(`${prefix}.informed-template`, { name });
    },
    isSuggestDisabled() {
      return this.isLoading || !this.allowed || this.loadingError;
    },
  },

  mounted() {
    if (this.$store.getters['vacancy/isProfileSuggestedAlready'](this.id, this.vacancyId)) {
      this.submitted = true;
    }
  },

  methods: {
    onClickSuggest() {
      this.handleLoading(
        async () => {
          const suggestModel = {
            allowed: this.allowed,
            profileId: this.id,
          };
          this.$store.dispatch('vacancy/profileSuccesstion', { type: 'vacancy', profileId: this.id, vacancyId: this.vacancyId });
          await this.$module.vacancy.api.suggestByOwners(this.vacancyId, suggestModel);
          this.submitted = true;
          this.$emit('update');
        },
        {
          catchErrors: true,
          disableDispatchError: true,
        },
      );
    },
  },
};
</script>
