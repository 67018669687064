<template>
  <BCard class="c-vacancy-item" no-body>
    <BCardHeader class="p-0 bg-dark">
      <BRow>
        <BCol>
          <div class="p-3">
            <SOTagLabel>{{ internalId }}</SOTagLabel>
            <OVacancyLabelPosition class="ml-2">
              {{ position }}
            </OVacancyLabelPosition>
          </div>
        </BCol>

        <template v-if="variant === VARIANTS.OWNER">
          <BCol class="text-right" md="auto">
            <OVacancyStatus :status="status" variant="box" />
          </BCol>
        </template>
        <template v-else>
          <BCol class="text-right" md="auto">
            <OVacancyStatus :status="status" variant="box" />
            <OBookmark v-if="false" class="ml-4" :id="_id" bookmark-type="vacancy" />
          </BCol>
        </template>
      </BRow>
    </BCardHeader>

    <!-- content -->
    <BCardBody>
      <BRow>
        <BCol>
          <OValue :label="'vacancy.fields.location-label'">
            {{ location.label }}
          </OValue>
          <OValue :label="'vacancy.fields.employment-type-label'">
            {{ $t(`vacancy.employment-type.options.${employmentType}`) }}
          </OValue>

          <OValue v-if="variant === VARIANTS.OWNER" :label="'vacancy.fields.suggestion-count-label'">
            {{ suggestionsCount }}
          </OValue>
        </BCol>
        <BCol class="text-right" md="auto">
          <OValue :label="'vacancy.fields.available-label'">
            {{ available | available($t('generic.available.past')) }}
          </OValue>
          <OValue v-if="distance > -1" :label="'vacancy.fields.distance-label'">
            <ODistance :value="distance" />
          </OValue>
        </BCol>
      </BRow>

      <template v-if="variant === VARIANTS.SEARCH">
        <h5 class="mt-4">{{ $t('vacancy.fields.keywords-label') }}</h5>
        <OModelKeywords :keywords="keywords" />

        <h5 class="mt-3">{{ $t('vacancy.fields.tasks-label') }}</h5>
        <SOBulletList :list="tasks" />

        <h5 class="mt-3">{{ $t('vacancy.fields.salary-label') }}</h5>
        {{ salary }}
      </template>
    </BCardBody>

    <CVacancyToolbarOwner
      v-if="variant === VARIANTS.OWNER"
      slot="footer"
      :_id="_id"
      :status="status"
      @update="$emit('update')"
    />

    <CVacancyToolbarSearch v-else slot="footer" :_id="_id" />
  </BCard>
</template>

<script>
import { BCard, BCol, BRow, BCardHeader, BCardBody } from 'bootstrap-vue';
import SOTagLabel from 'library/components/src/components/so/tag-label';
import SOBulletList from 'library/components/src/components/so/bullet-list';
import OModelKeywords from '../../../../components/o/model/keywords';
import OValue from '../../../../components/o/value';
import OBookmark from '../../../../components/o/bookmark';
import ODistance from '../../../../components/o/distance';
import { available } from 'library/components/src/tools/filters/available';

import VARIANTS from './item-variant.enum';
import OVacancyLabelPosition from '../o/label/position';
import OVacancyStatus from '../o/status';
import CVacancyToolbarOwner from './toolbar/owner';
import CVacancyToolbarSearch from './toolbar/search';

const prefix = 'vacancy-item';
const variants = Object.values(VARIANTS);

export default {
  name: 'c-vacancy-item',
  components: {
    BCard,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    ODistance,
    SOTagLabel,
    SOBulletList,
    OModelKeywords,
    OValue,
    OVacancyStatus,
    CVacancyToolbarOwner,
    CVacancyToolbarSearch,
    OVacancyLabelPosition,
    OBookmark,
  },
  filters: {
    available,
  },
  props: {
    _id: String,
    status: String,
    internalId: String,
    position: String,
    employmentType: String,
    location: Object,
    distance: Number,
    available: Object,
    tasks: Array,
    keywords: Array,
    salary: String,
    variant: {
      type: String,
      validate: v => variants.includes(v),
      default: VARIANTS.SEARCH,
    },
    // props only for owner
    suggestionsCount: Number,
  },
  computed: {
    VARIANTS: () => VARIANTS,
  },
};
</script>
