<template>
  <div class="vw-vacancy-owner">
    <OLayoutBreadcrumb
      :group-title="$t(`navigation.vacancy-group-label`)"
      :section-title="$t(`${prefix}.breadcrumb.section-title`)"
    />
    <OSearchQuickfilter v-bind="quickfilterProps" class="mt-4 mb-4" v-model="status" @submit="onSubmit" />
    <CList v-bind="listProps" />
  </div>
</template>

<script>
import VACANCY_STATUS from 'library/src/models/vacancy-status.enum';
import VACANCY_ITEM_VARIANTS from '../c/item-variant.enum';
import { BContainer } from 'bootstrap-vue';
import CVacancyItem from '../c/item';
import CList from '../../../../components/c/list';
import OSearchQuickfilter from '../../../../components/c/search/quickfilter';
import OLayoutBreadcrumb from '../../../../components/o/layout/breadcrumb';

const prefix = 'vacancy.vw.owner';

const FILTER_ALL = 'all';

export default {
  components: {
    BContainer,
    CList,
    OSearchQuickfilter,
    OLayoutBreadcrumb,
  },
  data() {
    return {
      status: FILTER_ALL, // or one of VACANCY_STATUS,
    };
  },
  computed: {
    prefix: () => prefix,
    listProps() {
      return {
        itemComponent: CVacancyItem,
        filter: {
          status: this.status,
        },
        itemProps: {
          variant: VACANCY_ITEM_VARIANTS.OWNER,
        },
        listService: this.$module.vacancy.api.owner.bind(this.$module.vacancy.api),
      };
    },
    quickfilterProps() {
      return {
        filter: [FILTER_ALL, ...Object.values(VACANCY_STATUS)],
        prefix: `${prefix}.quickfilter`,
      };
    },
  },
  methods: {
    onSubmit() {},
  },
};
</script>
