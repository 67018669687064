<template>
  <span :class="className" :title="title">
    <SOIcon v-bind="iconProps" />
  </span>
</template>

<script>
import VACANCY_STATUS from 'library/src/models/vacancy-status.enum';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-vacancy-status',
  components: {
    SOIcon,
  },
  props: {
    status: String,
    variant: {
      type: String,
      default: 'icon',
    },
    neutral: Boolean,
  },
  computed: {
    className() {
      return [
        'o-vacancy-status',
        `has-status-${this.status}`,
        `variant-${this.variant}`,
        { 'bg-warning': this.status === VACANCY_STATUS.DRAFT && !this.neutral },
      ];
    },
    title() {
      return this.$t(`vacancy.status.options.${this.status}`);
    },
    iconProps() {
      return {
        size: 'small',
        name: this.status === VACANCY_STATUS.ACTIVE ? 'lock' : 'lock-open',
      };
    },
  },
};
</script>

<style lang="scss">
.o-vacancy-status {
  &.variant-box {
    display: inline-block;
    padding: 1rem 1.25rem;
    height: 100%;
  }
}
</style>
