<template>
  <span class="o-model-language">{{ label }}</span>
</template>

<script>
export default {
  name: 'o-model-language',
  props: {
    value: String,
  },
  computed: {
    label() {
      if (this.value) {
        return this.$t(`generic.language.${this.value}`);
      }
      return this.value;
    },
  },
};
</script>
