<template>
  <BButton
    variant="danger"
    v-prompt.click="{
      message: $t(`${prefix}.delete-prompt`),
      callback: { success: onClickDelete },
    }"
  >
    {{ $t(`${prefix}.button-label`) }}
  </BButton>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
const prefix = 'vacancy.c.button-delete';

/**
 * @events success after success response
 */
export default {
  name: 'c-vacancy-button-delete',
  mixins: [loadingMixin],
  components: {
    BButton,
  },
  props: {
    identity: String,
  },
  computed: {
    prefix: () => prefix,
  },
  methods: {
    async onClickDelete() {
      await this.handleLoading(
        async () => {
          await this.$module.vacancy.api.deleting(this.identity);
          this.$store.dispatch('notify', this.$t(`${prefix}.delete-success`), { $root: true });
          this.$emit('success');
        },
        {
          catchErrors: true,
        },
      );
    },
  },
};
</script>

<style scoped></style>
