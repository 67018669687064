<template>
  <div class="c-vacancy-reservated">
    <h5 v-if="!hideHeadline">{{ $t(`${prefix}.headline`) }}</h5>
    <BOverlay :show="isLoading">
      <div v-if="isEmpty" class="empty-label">
        {{ $t(`${prefix}.empty-label`) }}
      </div>
      <div v-else class="reservation-list">
        <CProfileItem
          v-for="profile in reservations"
          :key="profile._id"
          :variant="PROFILE_VARIANTS.reservation"
          :profile="profile"
        />
      </div>
    </BOverlay>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue';
import { VARIANTS } from '../../../../components/c/profile/item.enum';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
import CProfileItem from '../../../../components/c/profile/item';

const prefix = 'vacancy.c.reservated';

export default {
  name: 'c-vacancy-reservated',
  mixins: [loadingMixin],
  components: {
    CProfileItem,
    BOverlay,
  },
  data() {
    return {
      reservations: [],
    };
  },
  props: {
    vacancyId: String,
    hideHeadline: Boolean,
  },
  computed: {
    prefix: () => prefix,
    PROFILE_VARIANTS: () => VARIANTS,
    isEmpty() {
      return Array.isArray(this.reservations) ? this.reservations.length < 1 : false;
    },
  },
  beforeMount() {
    this.fetch();
    this.$on('updated', () => this.fetch());
  },
  methods: {
    onUpdate() {
      this.fetch();
      this.$emit('update');
    },
    async fetch() {
      await this.handleLoading(
        async () => {
          this.reservations = await this.$module.vacancy.api.getReservationsById(this.vacancyId);
        },
        {
          catchErrors: true,
          disableDispatchError: true,
        },
      );
    },
  },
};
</script>
