import { render, staticRenderFns } from "./bookmark.vue?vue&type=template&id=a8643398&scoped=true"
import script from "./bookmark.vue?vue&type=script&lang=js"
export * from "./bookmark.vue?vue&type=script&lang=js"
import style0 from "./bookmark.vue?vue&type=style&index=0&id=a8643398&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8643398",
  null
  
)

export default component.exports