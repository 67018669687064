<template>
  <div class="o-layout-breadcrumb bg-secondary text-white py-2">
    <BContainer>
      <h2 class="my-0">
        <span class="text-uppercase font-weight-bold">
          {{ groupTitle }}
        </span>
        <span> / </span>
        <span>{{ sectionTitle }}</span>
      </h2>
    </BContainer>
  </div>
</template>

<script>
import { BContainer } from 'bootstrap-vue';

export default {
  name: 'o-layout-breadcrumb',
  components: { BContainer },
  props: {
    groupTitle: String,
    sectionTitle: String,
  },
};
</script>
