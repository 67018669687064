<template>
  <div class="vw-vacancy-bookmark">
    <OLayoutBreadcrumb
      :group-title="$t(`navigation.vacancy-group-label`)"
      :section-title="$t(`navigation.bookmark-vacancy-label`)"
    />
    list
  </div>
</template>

<script>
import OLayoutBreadcrumb from '../../../../components/o/layout/breadcrumb';

export default {
  components: { OLayoutBreadcrumb },
};
</script>
