<template>
  <span class="o-distance">{{ label }}</span>
</template>

<script>
import { numeric as numericFilter } from 'library/components/src/tools/filters/numeric';
export default {
  name: 'o-distance',
  props: {
    value: [String, Number],
  },
  computed: {
    label() {
      const distance = numericFilter(this.value);
      if (distance < 1) {
        return this.$t('fields.distance-match-value');
      } else {
        return this.$t('fields.distance-value', { distance });
      }
    },
  },
};
</script>
