<template>
  <div class="c-list">
    <BOverlay :show="isLoading" variant="transparent" rounded="sm">
      <BContainer class="items">
        <template v-if="isLoading">
          <slot v-if="$slots.loading" name="loading" />
        </template>
        <template v-else-if="isEmpty">
          <slot v-if="$slots.empty" name="empty" />
          <div v-else class="empty-label">
            {{ $t(`${prefix}.empty-label`) }}
          </div>
        </template>
        <component
          v-for="item in list"
          :is="itemComponent"
          :key="item._id"
          v-bind="getItemProps(item)"
          @update="onChange"
        />
      </BContainer>
      <CPagination v-if="showPagination" v-model="pagination" />
    </BOverlay>
  </div>
</template>

<script>
import { BContainer, BOverlay } from 'bootstrap-vue';
import CPagination from './pagination';

const STATUS_LOADING = 'loading';
const STATUS_LOADED = 'loaded';

const ITEMS_PER_PAGE = 8;
const PAGINATION = { itemsPerPage: ITEMS_PER_PAGE, total: 0, currentPage: 1 };

const prefix = 'c.list';

export default {
  name: 'c-list',
  components: {
    BContainer,
    CPagination,
    BOverlay,
  },
  data() {
    return {
      status: null,
      list: [],
      currentPage: PAGINATION.currentPage,
      pagination: { ...PAGINATION },
    };
  },
  props: {
    itemProps: Object,
    filter: Object,
    listService: Function,
    itemComponent: Object,
  },
  computed: {
    prefix: () => prefix,
    isEmpty() {
      return this.list.length < 1;
    },
    isLoading() {
      return this.status === STATUS_LOADING;
    },
    showPagination() {
      return !(this.isEmpty || this.isLoading);
    },
  },
  watch: {
    filter(v) {
      this.$logger.info('fetch by new filter', v);
      // clear pagination
      this.pagination = { ...PAGINATION };
      this.fetch();
    },
    pagination(v) {
      if (v.currentPage !== this.currentPage) {
        this.currentPage = v.currentPage;
      }
    },
    currentPage() {
      this.fetch();
    },
  },

  // mounted
  beforeMount() {
    this.fetch();
  },

  // methods
  methods: {
    onChange() {
      this.fetch();
    },
    getItemProps(item) {
      return {
        ...item,
        ...this.itemProps,
      };
    },
    async fetch() {
      if (this.isLoading) {
        return;
      }

      this.status = STATUS_LOADING;

      try {
        const filtering = {
          ...this.filter,
          ...this.pagination,
        };

        const result = await this.listService(filtering);
        this.list = result.list;
        this.pagination = result.pagination;

        this.$emit('list-loaded', this.list);
      } catch (error) {
        this.$emit('error', error);
        this.$store.dispatch('error', error, { root: true });
      }

      this.status = STATUS_LOADED;
    },
  },
};
</script>

<style scoped></style>
