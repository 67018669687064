<template>
  <div class="o-vacancy-toolbar-owner button-container text-right">
    <template v-if="isPublished">
      <BButton variant="warning" @click="onSelectStatus(VACANCY_STATUS.DRAFT)">
        {{ $t(`${prefix}.button-edit-label`) }}
      </BButton>
      <BButton variant="primary" :to="detailRoute">
        {{ $t(`${prefix}.button-detail-label`) }}
      </BButton>
    </template>
    <template v-else>
      <BButton variant="primary" @click="onSelectStatus(VACANCY_STATUS.ACTIVE)">
        {{ $t(`${prefix}.button-publish-label`) }}
      </BButton>
      <BButton variant="primary" :to="editRoute">
        {{ $t(`${prefix}.button-edit-label`) }}
      </BButton>
      <CButtonDelete :identity="_id" @success="$emit('update')" />
    </template>
  </div>
</template>

<script>
import VACANCY_STATUS from 'library/src/models/vacancy-status.enum';
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
import CButtonDelete from '../../c/button/delete';

const prefix = 'vacancy.c.toolbar-owner';

/**
 * @event onStatusUpdate
 */
export default {
  name: 'c-vacancy-toolbar-owner',
  mixins: [loadingMixin],
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    CButtonDelete,
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
    VACANCY_STATUS: () => VACANCY_STATUS,
    editRoute() {
      return {
        name: 'vacancy.edit',
        params: { id: this._id },
      };
    },
    detailRoute() {
      return {
        name: 'vacancy.detail',
        params: { id: this._id },
      };
    },
    isEditable() {
      return this.status === VACANCY_STATUS.DRAFT;
    },
    isPublished() {
      return this.status !== VACANCY_STATUS.DRAFT;
    },
    statusText() {
      return this.$t(`vacancy.status.options.${this.status}`);
    },
    statusOptions() {
      return Object.values(VACANCY_STATUS).map(status => {
        return {
          label: this.$t(`vacancy.status.options.${status}`),
          value: status,
        };
      });
    },
  },
  methods: {
    onSelectStatus(statusOption) {
      if (statusOption !== this.status) {
        this.handleLoading(
          async () => {
            await this.$module.vacancy.api.updateStatus(this._id, statusOption);
            this.$emit('update');
          },
          {
            catchErrors: true,
          },
        );
      }
    },
  },
};
</script>
