<template>
  <OInputAbstract class="o-input-select-language" v-bind="abstractProps">
    <BFormSelect :data-identity="identity" :options="options" v-model="model" />
  </OInputAbstract>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import OInputAbstract from '../abstract';
import { asOptions } from 'library/src/utilities/as-options';

export default {
  name: 'o-input-select-country',
  mixins: [OInputAbstract],
  components: { OInputAbstract, BFormSelect },
  model: {
    prop: 'value',
    event: 'value',
  },
  props: {
    value: {},
    identity: String,
    label: {
      type: String,
      default() {
        return this.$t('o.select-language.label');
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('value', v);
      },
    },
    options() {
      return asOptions(this.$t('generic.language'));
    },
  },
};
</script>
