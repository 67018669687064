<template>
  <OInputAbstract class="o-vacancy-input-position" v-bind="abstractProps">
    <BInputGroup>
      <BFormInput
        autofocus
        autocomplete="off"
        data-identity="vacancy-input-position"
        :state="validationState"
        v-model="model"
        @keyup.esc="onBlur"
        @blur="onBlur"
        @keyup="onChange"
      />
      <BInputGroupText>
        {{ genderPrefix }}
      </BInputGroupText>
    </BInputGroup>
    <OSuggestions :items="options" @item-clicked="onOptionClicked" />
  </OInputAbstract>
</template>

<script>
import { delay } from 'library/src/utilities/delay';
import { languageMixin } from 'library/components/src/tools/mixins/language.mixin';
import { BFormInput, BInputGroup, BInputGroupText } from 'bootstrap-vue';
import OInputAbstract from '../../../../../components/o/input/abstract';
import OSuggestions from '../../../../../components/o/suggestions';

const prefix = 'vacancy.o.input-position';

export default {
  name: 'o-vacancy-input-position',
  mixins: [languageMixin, OInputAbstract],
  components: {
    OSuggestions,
    OInputAbstract,
    BFormInput,
    BInputGroup,
    BInputGroupText,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      isTouched: false,
      options: null,
    };
  },
  props: {
    label: {
      type: String,
      default() {
        return this.$t(`${prefix}.label`);
      },
    },
    value: String,
    description: {
      type: String,
      default() {
        return this.$t(`${prefix}.description`);
      },
    },
  },
  computed: {
    prefix: () => prefix,
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      },
    },
    genderPrefix() {
      return this.$t(`generic.gender-prefix`);
    },
    validationState() {
      // not invalid if have one entry
      if (this.model) {
        return null;
      }

      return this.isTouched ? false : null;
    },
  },
  beforeMount() {
    const { language } = this;
    this.$store.dispatch('position/fetchLanguage', { language });
  },
  methods: {
    onOptionClicked(positionItem) {
      let { label } = positionItem;
      label = label.replace(this.genderPrefix, '').trim(); // filter gender prefix of label
      this.model = label;
      this.options = [];
    },
    onChange() {
      const input = this.model;
      this.isTouched = true;

      if (input) {
        this.options = this.$store.getters['position/findPositionByInputFilter'](input);
      } else {
        this.options = [];
      }
    },
    onBlur() {
      delay(200).then(() => {
        this.options = [];
      });
    },
  },
};
</script>
