<template>
  <span class="o-vacancy-label-position">
    <slot />
    {{ $t(`generic.gender-prefix`) }}
  </span>
</template>

<script>
export default {
  name: 'o-vacancy-label-position',
};
</script>
