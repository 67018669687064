<template>
  <div class="vw-vacancy-search">
    <OLayoutBreadcrumb
      :group-title="$t(`navigation.vacancy-group-label`)"
      :section-title="$t(`navigation.search-vacancy-label`)"
    />

    <CVacancyFilter class="mt-4 mb-4" v-model="filter" />
    <CList v-bind="listProps" @list-loaded="onListLoaded" />
  </div>
</template>

<script>
import VACANCY_ITEM_VARIANTS from '../c/item-variant.enum';

import OLayoutBreadcrumb from '../../../../components/o/layout/breadcrumb';
import { BContainer } from 'bootstrap-vue';
import CVacancyFilter from '../c/filter';
import CVacancyItem from '../c/item';
import CList from '../../../../components/c/list';

export default {
  components: {
    BContainer,
    CList,
    CVacancyFilter,
    OLayoutBreadcrumb,
  },
  data() {
    return {
      filter: null,
    };
  },
  computed: {
    listProps() {
      return {
        itemComponent: CVacancyItem,
        itemProps: {
          variant: VACANCY_ITEM_VARIANTS.SEARCH,
        },
        filter: this.filter,
        listService: this.$module.vacancy.api.search.bind(this.$module.vacancy.api),
      };
    },
  },
};
</script>
