<template>
  <div class="vw-vacancy-create">
    <OLayoutBreadcrumb
      :group-title="$t(`navigation.vacancy-group-label`)"
      :section-title="$t(`${prefix}.breadcrumb.section-title`)"
    />
    <BContainer class="mt-4">
      <BCard>
        <form autofocus novalidate autocomplete="off">
          <OVacancyInputPosition required autofocus v-model="position" />
          <OInputGeolocation required :label="$t(`vacancy.fields.location-label`)" v-model="location" />
          <OInputModelAvailability
            required
            prefix="vacancy.fields.available"
            :label="$t(`vacancy.fields.available-label`)"
            v-model="available"
          />
          <OInputOption v-bind="employmentTypeProps" v-model="employmentType" />
          <OInputValue required :label="$t(`vacancy.fields.company-business-label`)" v-model="business" />
          <OInputSelectLanguage :label="$t(`vacancy.fields.company-language-label`)" v-model="language" />
          <OInputTextarea :label="$t(`vacancy.fields.short-description-label`)" v-model="shortDescription" />
          <OInputTagsInput required tag-variant="flow-row" :label="$t(`vacancy.fields.tasks-label`)" v-model="tasks" />
          <OInputTagsInput
            required
            tag-variant="flow-row"
            :label="$t(`vacancy.fields.keywords-label`)"
            v-model="keywords"
          />
          <OInputTagsInput
            required
            tag-variant="flow-row"
            :label="$t(`vacancy.fields.requirements-label`)"
            v-model="requirements"
          />
          <OInputTagsInput
            required
            tag-variant="flow-row"
            :label="$t(`vacancy.fields.benefits-label`)"
            v-model="benefits"
          />
          <OInputValue
            required
            :label="$t(`vacancy.fields.salary-label`)"
            :description="$t(`vacancy.fields.salary-description`)"
            v-model="salary"
          />
          <OInputTextarea :label="$t(`vacancy.fields.notes-label`)" v-model="notes" />

          <SOErrorMessage translation-key="vacancy.response-errors" use-validation :error="loadingError" />

          <div class="button-container text-right" slot="footer">
            <BButton variant="dark" :to="{ name: 'vacancy.owner' }">
              {{ $t(`${prefix}.button-cancel-label`) }}
            </BButton>
            <BButton variant="primary" :disabled="isLoading" @click="onSubmit">
              {{ $t(`${prefix}.button-submit-label`) }}
              <BSpinner v-if="isLoading" small />
            </BButton>
          </div>
        </form>
      </BCard>
    </BContainer>
  </div>
</template>

<script>
import { BButton, BCard, BContainer } from 'bootstrap-vue';

import OLayoutBreadcrumb from '../../../../components/o/layout/breadcrumb';
import OInputGeolocation from '../../../../components/o/input/geolocation';
import OInputDate from '../../../../components/o/input/date';
import OInputModelAvailability from '../../../../components/o/input/model/availability';
import OInputTextarea from '../../../../components/o/input/textarea';
import OInputValue from '../../../../components/o/input/value';
import OInputTagsInput from '../../../../components/o/input/tags/input';
import OInputOption from '../../../../components/o/input/option';
import OVacancyInputPosition from '../o/input/position';
import OInputUpload from '../../../../components/o/input/upload';
import { TYPE_IMAGE } from '../../../../components/o/input/upload-type.enum';
import OInputSelectLanguage from '../../../../components/o/input/select/language';

import SOErrorMessage from 'library/components/src/components/so/error-message';
import { toOptions } from 'library/components/src/tools/filters/to-options';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
import { onlyProps } from 'library/src/utilities/only-props';
import { computedMapping } from 'library/src/utilities/vueex/computed-mapping';
import { EMPLOYER } from 'library/src/models/account-type.enum';

const prefix = 'vacancy.vw.create';

// define data and resolve props
const MODEL_PROPS = {
  position: null,
  location: null,
  business: null,
  employmentType: 'full-time',
  available: null,
  language: null,
  shortDescription: null,
  tasks: [],
  keywords: [],
  benefits: [],
  requirements: [],
  salary: null,
  notes: null,
};

export default {
  mixins: [loadingMixin],
  components: {
    BContainer,
    BCard,
    BButton,
    OLayoutBreadcrumb,
    OInputGeolocation,
    OInputDate,
    OInputTextarea,
    OInputValue,
    OInputModelAvailability,
    OInputTagsInput,
    OInputOption,
    SOErrorMessage,
    OVacancyInputPosition,
    OInputUpload,
    OInputSelectLanguage,
  },

  data() {
    return { ...MODEL_PROPS };
  },
  computed: {
    ...computedMapping('currentAccount', ['companyName', 'accountType'], {
      noSetter: true,
      propertyPrefix: 'currentAccount',
    }),
    uploadType: () => TYPE_IMAGE,

    id() {
      return this.$route.params.id;
    },
    prefix: () => prefix,
    employmentTypeProps() {
      const prefix = 'vacancy.employment-type';

      return {
        as: 'select',
        label: this.$t(`${prefix}.label`),
        options: toOptions(this.$t(`${prefix}.options`)),
      };
    },
  },

  watch: {
    id() {
      this.reset();
    },
  },

  // hooks
  beforeMount() {
    this.reset();

    if (this.id) {
      this.handleLoading(
        async () => {
          let vacancyModel = await this.$module.vacancy.api.getById(this.id);
          vacancyModel = onlyProps(vacancyModel, Object.keys(MODEL_PROPS));
          Object.assign(this, vacancyModel);
        },
        {
          catchErrors: true,
        },
      );
    }
  },

  methods: {
    reset() {
      // reset params
      Object.keys(MODEL_PROPS).forEach(name => {
        this[name] = MODEL_PROPS[name];
      });
    },
    onSubmit() {
      this.handleLoading(
        async () => {
          const vacancyModel = onlyProps(this, Object.keys(MODEL_PROPS));

          if (this.id) {
            this.$logger.info('Update vacancy model', this.id, vacancyModel);
            await this.$module.vacancy.api.update(this.id, vacancyModel);
          } else {
            this.$logger.info('Create vacancy model', vacancyModel);
            await this.$module.vacancy.api.create(vacancyModel);
          }

          // clear model
          Object.assign(this, onlyProps({}, Object.keys(MODEL_PROPS), null, { preset: null }));

          Object.assign(this, onlyProps({}, ['tasks', 'keywords', 'benefits', 'requirements'], null, { preset: [] }));

          this.$router.push({ name: 'vacancy.owner' });
        },
        {
          catchErrors: true,
        },
      );
    },
  },
};
</script>
