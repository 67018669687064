<template>
  <BContainer class="vw-vacancy-detail mt-4">
    <BOverlay :show="isLoading" variant="transparent" rounded="sm">
      <template v-if="vacancy">
        <BCard no-body>
          <div class="vacancy-detail is-printable">
            <BCardBody>
              <BRow>
                <BCol>
                  <SOTagLabel>{{ vacancy.internalId }}</SOTagLabel>
                  <CVacancyLabelPosition class="ml-2">
                    {{ vacancy.position }}
                  </CVacancyLabelPosition>
                </BCol>
                <BCol class="text-right" md="auto">
                  <OVacancyStatus :status="vacancy.status" neutral />
                </BCol>
              </BRow>
            </BCardBody>

            <BCardHeader class="card-inner">
                <h3 class="label d-inline text-weight-normal">
                  {{ $t(`${prefix}.business-label`) }}
                </h3>
              <h3 class="business-name m-0 pl-2 d-inline">
                {{ vacancy.business }}
              </h3>
            </BCardHeader>
            <BCardBody>
              <BRow>
                <BCol>
                  <OValue :label="'vacancy.fields.location-label'">
                    {{ vacancy.location.label }}
                  </OValue>
                  <OValue :label="'vacancy.fields.employment-type-label'">
                    {{ $t(`vacancy.employment-type.options.${vacancy.employmentType}`) }}
                  </OValue>
                  <OValue :label="'vacancy.fields.company-language-label'">
                    <OModelLanguage :value="vacancy.language" />
                  </OValue>
                </BCol>
                <BCol class="text-right" md="auto">
                  <OValue :label="'vacancy.fields.available-label'">
                    {{ vacancy.available | available($t('generic.available.past')) }}
                  </OValue>
                </BCol>
              </BRow>

              <h5 class="mt-3">{{ $t(`vacancy.fields.keywords-label`) }}</h5>
              <OModelKeywords :keywords="vacancy.keywords" />

              <h5 class="mt-3">{{ $t('vacancy.fields.tasks-label') }}</h5>
              <SOBulletList :list="vacancy.tasks" />

              <h5 class="mt-3">{{ $t('vacancy.fields.benefits-label') }}</h5>
              <SOBulletList :list="vacancy.benefits" />

              <h5 class="mt-3">{{ $t('vacancy.fields.requirements-label') }}</h5>
              <SOBulletList :list="vacancy.requirements" />

              <h5 class="mt-3">{{ $t('vacancy.fields.salary-label') }}</h5>
              <div class="label">{{ vacancy.salary }}</div>

              <h5 v-if="vacancy.notes" class="mt-3">{{ $t('vacancy.fields.notes-label') }}</h5>
              <div class="label">{{ vacancy.notes }}</div>
            </BCardBody>
          </div>

          <div slot="footer" class="toolbar is-not-printable button-container is-not-printable">
            <BButton variant="dark" :to="{ name: 'vacancy.owner' }">
              {{ $t(`${prefix}.button-owner-label`) }}
            </BButton>
            <BButton variant="primary" @click="onClickPrint">
              {{ $t(`${prefix}.button-print-label`) }}
            </BButton>
          </div>
        </BCard>

        <BTabs v-if="rights.canDisplay" lazy class="is-not-printable">
          <template v-if="rights.canManage">
            <BTab>
              <template #title>
                {{ $t(`${prefix}.tab-suggestions`) }}
              </template>
              <CVacancySuggestions hide-headline class="is-not-printable" :vacancy-id="id" />
            </BTab>
            <BTab>
              <template #title>
                {{ $t(`${prefix}.tab-reservated`) }}
              </template>
              <CVacancyReservated hide-headline ref="reservated" class="is-not-printable" :vacancy-id="id" />
            </BTab>
          </template>

          <BTab v-else-if="rights.canSuggest" :title="$t(`${prefix}.tab-owners`)">
            <CVacancyOwners hide-headline :id="id" />
          </BTab>
          <BTab v-else-if="!rights.canManage" :title="$t(`${prefix}.tab-owners`)">
            {{ $t(`${prefix}.suggestion-not-allowed`) }}
          </BTab>
        </BTabs>
      </template>

      <SOErrorMessage class="is-not-printable" translation-key="vacancy.response-errors" :error="loadingError" />
    </BOverlay>
  </BContainer>
</template>

<script>
import { BButton, BCol, BContainer, BOverlay, BRow, BCard, BTabs, BTab, BCardBody, BCardHeader } from 'bootstrap-vue';
import CVacancySuggestions from '../c/suggestions';
import CVacancyReservated from '../c/reservated';
import SOTagLabel from 'library/components/src/components/so/tag-label';
import OVacancyStatus from '../o/status';
import SOBulletList from 'library/components/src/components/so/bullet-list';
import OModelKeywords from '../../../../components/o/model/keywords';
import OValue from '../../../../components/o/value';
import CVacancyToolbarOwner from '../c/toolbar/owner';
import CVacancyToolbarSearch from '../c/toolbar/search';
import SOErrorMessage from 'library/components/src/components/so/error-message';
import { available } from 'library/components/src/tools/filters/available';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
import CVacancyOwners from '../c/owners';
import CVacancyLabelPosition from '../o/label/position';
import OThumbnail from '../../../../components/o/thumbnail';
import OModelLanguage from '../../../../components/o/model/language';
import { SIZE } from '../../../../components/o/thumbnail-size.enum';

const prefix = 'vacancy.vw.detail';

export default {
  mixins: [loadingMixin],
  components: {
    CVacancyOwners,
    BContainer,
    BCol,
    BRow,
    SOTagLabel,
    SOBulletList,
    OModelKeywords,
    OValue,
    OVacancyStatus,
    CVacancyToolbarOwner,
    CVacancyToolbarSearch,
    BButton,
    BOverlay,
    SOErrorMessage,
    CVacancySuggestions,
    CVacancyReservated,
    CVacancyLabelPosition,
    BCard,
    BTabs,
    BTab,
    OThumbnail,
    BCardBody,
    BCardHeader,
    OModelLanguage,
  },
  filters: {
    available,
  },

  data() {
    return {
      vacancy: null,
    };
  },
  computed: {
    prefix: () => prefix,
    logoSize: () => SIZE.SMALL,
    id() {
      return this.$route.params.id;
    },
    rights() {
      return this.vacancy ? this.vacancy.rights : {};
    },
    showTabs() {
      return this.rights.canManage || this.rights.canSuggest;
    },
  },

  beforeEnter() {
    // check if user already accept agb
  },

  beforeMount() {
    this.fetchModel();
  },

  methods: {
    async fetchModel() {
      await this.handleLoading(
        async () => {
          this.vacancy = await this.$module.vacancy.api.getById(this.id);
        },
        {
          catchErrors: true,
          disableDispatchError: true,
        },
      );
    },
    onClickPrint() {
      window.print();
    },
  },
};
</script>

<style lang="scss">
@import '../../../../component';

.vw-vacancy-detail {
  /** @todo maybe a generic problem? **/
  .so-bullet-list {
    padding-left: 1.2rem;
  }

  .text-weight-normal {
    font-weight: normal;
  }
}
</style>
