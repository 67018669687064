<template>
  <ODialogContent
    v-model="opening"
    model-class="vacancy-o-dialog-access"
    name="vacancy-access-dialog"
    important
    hide-cancel-button
    hide-close-button
    :success-button-label="$t(`${prefix}.success-label`)"
    remember-answer-key="vacancy-access-dialog"
    @cancel="onCancel"
    @success="onSuccess"
  />
</template>

<script>
import { BButton } from 'bootstrap-vue';
import ODialogContent from '../../../../../components/o/dialog/content';

const prefix = 'vacancy.o.dialog-access';

export default {
  name: 'o-vacancy-dialog-access',
  components: {
    BButton,
    ODialogContent,
  },
  model: {
    prop: 'open',
    event: 'open',
  },
  props: {
    open: Boolean,
    acceptRoute: {
      type: Object,
      required: true,
    },
    cancelRoute: Object,
  },
  computed: {
    prefix: () => prefix,
    opening: {
      get() {
        return this.open;
      },
      set(v) {
        this.$emit('open', v);
      },
    },
  },
  methods: {
    onCancel() {
      if (this.cancelRoute) {
        this.$router.push(this.cancelRoute);
      }
      this.$emit('open', false);
    },
    onSuccess() {
      this.$router.push(this.acceptRoute);
      this.$emit('open', false);
    },
  },
};
</script>
