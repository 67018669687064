<template>
  <div class="o-vacancy-toolbar-search button-container text-right">
    <BButton variant="primary" @click="openDialog = true">
      {{ buttonLabel }}
    </BButton>
    <OVacancyDialogAccess :accept-route="acceptRoute" v-model="openDialog" />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import OVacancyDialogAccess from '../../o/dialog/access';

const prefix = 'vacancy.c.toolbar-search';

export default {
  name: 'c-vacancy-toolbar-search',
  components: {
    BButton,
    OVacancyDialogAccess,
  },
  data() {
    return {
      openDialog: false,
    };
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
  },
  computed: {
    prefix: () => prefix,
    acceptRoute() {
      return {
        name: 'vacancy.detail',
        params: {
          id: this._id,
        },
      };
    },
    buttonLabel() {
      const key = this.hasSuggestionAlready ? 'button-open-label-suggestion' : 'button-open-label';
      return this.$t(`${prefix}.${key}`);
    },
    hasSuggestionAlready() {
      return this.$store.getters['vacancy/hasSuggestionAlready'](this._id);
    }
  },
};
</script>
