<template>
  <span class="o-bookmark" :title="title">
    <SOIcon class="clickable highlight" name="bookmark" :variant="iconVariant" @click.native="onClick" />
    <BSpinner v-if="isLoading" class="loader" />
  </span>
</template>
<script>
import { BSpinner } from 'bootstrap-vue';
import SOIcon from 'library/components/src/components/so/icon';

export default {
  name: 'o-bookmark',
  components: { SOIcon, BSpinner },
  props: {
    prefix: {
      type: String,
      default: 'o.bookmark',
    },
    id: {
      type: String,
      required: true,
    },
    bookmarkType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    iconVariant() {
      return this.isBookmarked ? 'solid' : 'outline';
    },
    title() {
      const key = this.isBookmarked ? 'title-is-bookmarked' : 'title-no-bookmarked';
      return this.$t(`${this.prefix}.${key}`);
    },
    isBookmarked: {
      get() {
        return this.$store.getters[`${this.bookmarkType}/isBookmarkedById`](this.id);
      },
      set() {
        this.isLoading = true;
        this.$store.dispatch(`${this.bookmarkType}/toggleBookmark`, this.id).then(() => {
          this.isLoading = false;
          this.$emit('change', this.isBookmarked);
        });
      },
    },
  },
  methods: {
    onClick() {
      if (!this.isLoading) {
        this.isBookmarked = !this.isBookmarked;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../component';

.o-bookmark {
  position: relative;
}

.loader {
  @include rect(0.5rem);
  border-width: 0.1rem;
  @include position(0, 0);
  margin: {
    right: -50%;
    top: -50%;
  }
}
</style>
