<template>
  <div class="c-vacancy-owners">
    <h4 v-if="!hideHeadline">{{ $t(`${prefix}.headline`) }}</h4>
    <CProfileList v-bind="listProps" />
    <div class="mt-3 button-container text-center">
      <BButton variant="primary" @click="onClickStartCreateProfile">
        {{ $t(`${prefix}.profile-create-button-label`) }}
      </BButton>
    </div>
  </div>
</template>

<script>
import OWNER_FILTERS from 'library/src/models/filter/owner.enum';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';
import CProfileList from '../../../../components/c/profile/list';
import OVacancyProfileSuggestion from '../o/profile/suggestion';

const prefix = 'vacancy.c.owners';

export default {
  name: 'c-vacancy-owners',
  mixins: [loadingMixin],
  components: {
    CProfileList,
  },
  data() {
    return {
      profiles: [],
    };
  },
  props: {
    id: String,
    hideHeadline: Boolean,
  },
  computed: {
    prefix: () => prefix,
    listProps() {
      return {
        containerExpanded: true,
        itemComponent: OVacancyProfileSuggestion,
        filter: {
          filter: OWNER_FILTERS.IMAGINABLE,
          itemsPerPage: 5,
        },
        itemProps: {
          vacancyId: this.id,
        },
        listService: this.$api.profile.pagingByOwner.bind(this.$api.profile),
      };
    },
  },
  beforeMount() {},
  methods: {
    onClickStartCreateProfile() {
      this.$router.push({ name: 'direct-pv.profile.create' });
    },
  },
};
</script>
