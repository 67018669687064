<template>
  <BContainer class="c-vacancy-filter">
    <BCard>
      <BRow>
        <BCol sm="12">
          <OVacancyInputPosition
            :label="$t(`${prefix}.position-field-label`)"
            description=""
            :required="false"
            v-model="position"
          />
        </BCol>
        <BCol sm="6">
          <OInputGeolocation :label="$t(`${prefix}.location-field-label`)" v-model="location" />
        </BCol>
        <BCol sm="6">
          <OInputOption v-bind="employmentTypeProps" v-model="employmentType" />
        </BCol>
      </BRow>

      <div class="button-container text-right" slot="footer">
        <BButton variant="secondary" @click="onCancel">
          {{ $t(`${prefix}.button-cancel-label`) }}
        </BButton>
        <BButton variant="primary" @click="onSubmit">
          {{ $t(`${prefix}.button-submit-label`) }}
        </BButton>
      </div>
    </BCard>
  </BContainer>
</template>

<script>
import { onlyProps } from 'library/src/utilities/only-props';
import { assign } from 'library/src/utilities/assign';
import EMPLOYMENT_TYPE from 'library/src/models/employment-type.enum';
import { toOptions } from 'library/components/src/tools/filters/to-options';

import { BCard, BCol, BRow, BButton, BContainer } from 'bootstrap-vue';
import OInputGeolocation from '../../../../components/o/input/geolocation';
import OInputOption from '../../../../components/o/input/option';
import OInputValue from '../../../../components/o/input/value';
import OVacancyInputPosition from '../o/input/position';

const prefix = 'vacancy.c.filter';

const MODEL_PROPS = ['position', 'location', 'employmentType'];
const isValid = v => v === null || `${v}`.length === 0;

/**
 * @event change(filter) Model update
 * @event submit(filter) after press submit button and model update
 * @event cancel() after press cancel button
 */
export default {
  name: 'c-vacancy-filter',
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    OInputGeolocation,
    OInputOption,
    OInputValue,
    OVacancyInputPosition,
    BContainer,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      position: null,
      location: null,
      employmentType: EMPLOYMENT_TYPE.ANY,
    };
  },
  props: {
    value: Object,
  },
  computed: {
    prefix: () => prefix,
    /**
     * @return {{as: string, options: *[], label}}
     */
    employmentTypeProps() {
      const prefix = 'vacancy.employment-type';

      return {
        as: 'select',
        label: this.$t(`${prefix}.label`),
        options: toOptions(this.$t(`${prefix}.options`)),
      };
    },
  },
  beforeMount() {
    if (this.value) {
      assign(this, onlyProps(this.value, MODEL_PROPS, isValid));
    }
  },
  methods: {
    onSubmit() {
      const model = onlyProps(this, MODEL_PROPS, isValid);
      this.$emit('change', model);
      this.$emit('submit');
    },
    onCancel() {
      this.position = null;
      this.location = null;
      this.employmentType = EMPLOYMENT_TYPE.ANY;

      const model = onlyProps(this, MODEL_PROPS, isValid);
      this.$emit('change', model);
      this.$emit('reset');
    },
  },
};
</script>
