<template>
  <OProfileCard class="o-vacancy-profile-suggested" :data-id="id" v-bind="cardProps">
    <div v-if="false" slot="header-toolbar" class="header-col">
      <SOTimeleft :value="availableUntil" />
    </div>
    <div slot="header-toolbar" class="header-col color-set-warning">
      <SOIcon name="user-clock" size="md" />
    </div>
    <OProfileRequestStatus slot="header-toolbar" class="header-col" :id="id" />
    <OProfileInfo v-bind="profile" />
    <BCardBody slot="append" class="conditions color-set-softer has-top-border">
      <OToggle class="condition-toggle" v-model="isConditionOpen" :label="$t('profile.condition.opener-label')" />
      <OProfileConditions v-if="isConditionOpen" class="mt-3" v-bind="profile" />
    </BCardBody>
    <nav class="text-right button-container" slot="footer">
      <BButton variant="danger" @click="onClickDecline">
        {{ $t(`${prefix}.decline-label`) }}
      </BButton>
      <OReservationButton
        :id="id"
        :is-applicant-approval="isApplicantApproval"
        :label="$t(`${prefix}.accept-label`)"
        :relation-to="relation"
        @success="onClickAccept"
      />
    </nav>
  </OProfileCard>
</template>

<script>
import { BCol, BRow, BButton, BFormCheckbox, BCardBody } from 'bootstrap-vue';
import { fullname as fullnameFilter } from 'library/components/src/tools/filters/fullname';
import { loadingMixin } from 'library/components/src/tools/mixins/loading.mixin';

import OToggle from '../../../../../components/o/toggle';
import OProfileCard from '../../../../../components/o/profile/card';
import OProfileInfo from '../../../../../components/o/profile/info';
import OProfileRequestStatus from '../../../../../components/o/profile/request-status';
import OProfileConditions from '../../../../../components/o/profile/conditions';
import SOTimeleft from 'library/components/src/components/so/timeleft';
import SOIcon from 'library/components/src/components/so/icon';
import OReservationButton from '../../../../../components/o/reservation/button';
import get from 'lodash.get';

const prefix = 'vacancy.o.profile-suggested';

export default {
  name: 'o-vacancy-profile-suggested',
  mixins: [loadingMixin],
  components: {
    OProfileCard,
    BFormCheckbox,
    BButton,
    BCol,
    BCardBody,
    BRow,
    OToggle,
    OProfileInfo,
    OProfileRequestStatus,
    OProfileConditions,
    SOTimeleft,
    SOIcon,
    OReservationButton,
  },
  inheritAttrs: false,
  data() {
    return {
      accepted: false,
      isConditionOpen: false,
    };
  },
  props: {
    vacancyId: {
      type: String,
      required: true,
    },
    profile: Object,
  },
  computed: {
    prefix: () => prefix,
    id() {
      return this.profile._id;
    },
    cardProps() {
      const { internalId, person } = this.profile;
      return { person, internalId, asAnonym: true };
    },
    availableUntil() {
      return this.profile.availableUntil;
    },
    person() {
      return this.profile.person;
    },
    checkboxLabel() {
      const name = fullnameFilter(this.person);
      return this.$t(`${prefix}.informed-template`, { name });
    },
    isApplicantApproval() {
      const { profile } = this;

      if (profile.locationItem) {
        return profile.locationItem.applicantApproval;
      }

      return get(profile, 'workspace.applicantApproval');
    },
    relation() {
      return {
        type: 'vacancy',
        id: this.vacancyId,
      };
    },
  },

  methods: {
    onClickDecline() {
      this.handleLoading(
        async () => {
          await this.$module.vacancy.api.declineProfile(this.vacancyId, this.id);
          this.$emit('update', this);
        },
        { catchErrors: true },
      );
    },

    onClickAccept() {
      this.$emit('update');
    },
  },
};
</script>
